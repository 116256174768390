import $ from 'jquery';
import Hiraku from 'hiraku';
import stickyFooter from 'sticky-footer';
import SmoothScroll from 'smooth-scroll';
import tabby from 'tabby';
import wowjs from 'wowjs';
import headroomJS from "headroom.js";
import slickCarousel from "slick-carousel";

import addBrowserClass from './modules/browser';
import fontSetting from './modules/font';
import sub from './modules/sub';

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
stickyFooter.init({
    selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
    selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
    callback: function() {}, // Runs after the footer is stuck
});

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// slick-carousel JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
$(".p-profBody__Photo-wrapper").slick({
    // normal options...
    infinite: true,
    prevArrow: '<div class="p-profBody__Photo-prev p-profBody__Photo-icon"><i class="fas fa-arrow-alt-circle-left"></i></div>',
    nextArrow: '<div class="p-profBody__Photo-next p-profBody__Photo-icon"><i class="fas fa-arrow-alt-circle-right"></i></div>',
    speed: 500,
    dots: true,
    dotsClass: 'p-profBody__Photo-dots'
});

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// tabby JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
tabby.init({
    selectorToggle: '[data-tab]', // Tab toggle selector
    selectorToggleGroup: '[data-tabs]', // Tab toggle group selector
    selectorContent: '[data-tabs-pane]', // Tab content selector
    selectorContentGroup: '[data-tabs-content]', // Tab content group selector
    toggleActiveClass: 'is-active', // Class added to active toggle elements
    contentActiveClass: 'is-active', // Class added to active tab content areas
    initClass: 'js-tabby', // Class added to <html> element when initiated
    stopVideo: true, // [Boolean] If true, stop videos when tab closes
    callback: function(tabs, toggle) {} // Function that's run after tab content is toggled
});

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]');

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// headroom JS
// -------------------------------------------------------------------
// const header = document.querySelector('.l-header');
// let headroom = new headroomJS(header, {
//     //上から120pxより下の領域で10px以上スクロールするとイベントが発動する
//     "offset": 100,
//     "tolerance": 10,
//     "classes": {
//         "initial": "initial"
//     },
// });
// headroom.init();

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// WOWJS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
window.WOW = require('wowjs').WOW;
new WOW({
    mobile: false
}).init();

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// Hiraku JS
// -------------------------------------------------------------------
// -------------------------------------------------------------------
new Hiraku('.js-menu', {
    width: '100%',
    btn: ".js-menuTrigger",
    fixedHeader: ".l-header",
    closeBtn: '.js-menu-close'
});

// -------------------------------------------------------------------
// -------------------------------------------------------------------
// jQuery
// -------------------------------------------------------------------
// -------------------------------------------------------------------
$(function() {
    // プルダウン変更時に遷移
    $('select[name=selectorArchives]').change(function() {
        if ($(this).val() != '') {
            window.location.href = $(this).val();
        }
    });
});
